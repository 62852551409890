* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: inherit;
}

html{
  font-size: calc(.69444vw - 0px / 144);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

    /* Apply font smoothing */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    height: 100vh;
    box-sizing: border-box;
    scroll-behavior: smooth;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* @font-face {
  font-family: "Utah WGL Condensed W10 Bold";
  src: url("https://db.onlinewebfonts.com/t/48f1766ca457e77c058f6c1889734588.eot");
  src: url("https://db.onlinewebfonts.com/t/48f1766ca457e77c058f6c1889734588.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/48f1766ca457e77c058f6c1889734588.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/48f1766ca457e77c058f6c1889734588.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/48f1766ca457e77c058f6c1889734588.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/48f1766ca457e77c058f6c1889734588.svg#Utah WGL Condensed W10 Bold")format("svg");
} */



/* @font-face {
  font-family: "Utah WGL Condensed W10 Bold";
  src: url("/utahcondensedbold.ttf") format("truetype");
} */
